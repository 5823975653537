@charset "UTF-8";
.navbar-header-button {
  padding: 8px 1.5rem;
}

.page-content-button {
  padding: calc(55px + 1.5rem) 0.75rem 60px;
}

.bs5-alert-dismissible .btn-close {
  top: 0 !important;
}

.remove-date,
.reset-input {
  position: relative;
}

.remove-date:hover .reset,
.reset-input:hover .reset {
  display: block !important;
}

.remove-date .range-del,
.remove-date .reset,
.reset-input .range-del,
.reset-input .reset {
  position: absolute;
  bottom: 10px;
  right: 5px;
  top: auto;
  cursor: pointer;
  z-index: 10;
  color: #999;
  display: none;
}

.media {
  display: flex !important;
}

.setting-item-icon {
  width: 40px;
  height: 40px;
  background-color: #f4f6f8;
  text-align: center;
  vertical-align: bottom;
  margin-right: 10px;
  line-height: 40px;
  font-size: 20px;
  border-radius: 3px;
  color: #8b97a4;
}

.multiselect {
  background-color: #fff;
  border-color: #d8dbe0;
  color: #768192;
}

.form-group {
  margin-bottom: 1rem;
}

.input-step-sm {
  height: 28.38px;
}
.input-step-sm input {
  width: 3em;
  font-size: 0.765625rem;
}
.input-step-sm button {
  font-size: 1.2em;
  width: 1.2em;
}

.card .line-info {
  display: flex;
  margin-bottom: 7px;
}
.card .line-info .title {
  width: 40%;
  float: left;
  display: flex;
  color: #747C87;
}
.card .line-info .content {
  width: 60%;
  float: left;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  color: #333;
}

.group-inline-tab-button {
  position: absolute;
  right: 15px;
  top: 12px;
}

/* NAV TABS LINK */
ul.tab-filter {
  border-bottom: 1px solid #f4f4f4;
}

ul.tab-filter li:first-child {
  width: 150px;
  padding: 0;
  margin: 0;
  border-right: 1px solid #f4f4f4;
}

ul.tab-filter li:first-child a {
  padding-left: 0;
}
ul.tab-filter li:first-child a:hover {
  color: #3c4b64;
}

ul.tab-filter li a {
  padding: 5px 10px;
  color: #3c4b64;
  border-radius: 0;
  background: transparent;
  margin: 0;
  display: block;
  font-size: 13px;
}
ul.tab-filter li a:hover {
  text-decoration: none;
  color: #321fdb;
}

ul.tab-filter li > a.active {
  color: #321fdb;
  font-weight: bold;
}

ul.tab-filter-first li a {
  padding-top: 0;
}

.box-stamp {
  position: absolute;
  left: 32%;
  top: 10%;
}

.stamp-pay-success {
  width: 200px;
  position: relative;
  top: 0;
  right: 0;
}

.attachments .attachment-box {
  border: 1px solid #eee;
}

.attachment-name {
  color: #666;
  font-weight: 700;
  height: 40px;
  display: block;
}

.attachment-icon,
.attachment-info,
.attachment-size {
  display: block;
}

.attachment-info {
  background-color: #f8f9fa;
  padding: 10px;
}

.attachment-size {
  color: #999;
  font-size: 12px;
}
.attachment-size > span {
  display: inline-block;
}

.attachment-icon {
  color: #666;
  font-size: 65px;
  height: 150px;
  padding: 20px 10px;
  text-align: center;
  overflow: hidden;
}
.attachment-icon.has-img {
  padding: 0;
}
.attachment-icon.has-img > img {
  width: 100%;
  height: 100%;
}

.collapse-filter-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
@media (max-width: 767px) {
  .collapse-filter-btn {
    top: unset;
    bottom: 1rem;
  }
}

.collapse-icon[aria-expanded=true] .la-angle-down {
  transform: rotate(180deg);
  transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-down {
  transform: rotate(180deg);
  transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-double-down {
  transform: rotate(180deg);
  transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-double-down {
  transform: rotate(180deg);
  transition: all 0.6s;
}

.diff-color {
  background-color: #f2f2f2;
}

.btn-outline-purple {
  color: #6558f5;
  border-color: #6558f5;
}
.btn-outline-purple:hover {
  background-color: #6558f5;
  color: #fff;
}

.alert-count {
  width: 25px;
  height: 25px;
  line-height: 18px;
}

.caret-right {
  transform: rotate(0deg);
  transition: all 0.6s;
}
.caret-right.active {
  transform: rotate(90deg);
}

.tw-nav {
  padding: 0;
}
.tw-nav .nav-tabs {
  border: unset;
}
.tw-nav .nav-tabs .nav-item .nav-link.active {
  position: relative;
  background-color: #fff;
}
.tw-nav .nav-tabs .nav-item .nav-link.active:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: #3d78e3;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-purple {
  background-color: #6558f5;
  color: #fff;
}
.btn-purple:hover {
  background-color: #fff;
  color: #6558f5;
  border: 1px solid #6558f5;
}

.full-width {
  width: 100%;
}

@media (max-width: 767px) {
  .order-navigate-popup .left-block button {
    width: 99%;
  }
  .order-navigate-popup .right-block {
    margin-top: 3px;
  }
  .order-navigate-popup .right-block .bill-popup-add-btn {
    width: 58%;
  }
  .order-navigate-popup .right-block .bill-popup-close-btn {
    width: 40%;
  }
}

.text-purple {
  color: #6558f5;
}

.order-header {
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;
}
@media (max-width: 767px) {
  .order-header {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
}
.order-header .order-code, .order-header .order-request-form {
  width: 100%;
}

.content-bill {
  padding-top: 14px;
  border-top: 1px dashed #ccc;
}

.nav-link-order[data-bs-toggle=collapse]:after {
  right: -6px !important;
}

.over-flow-fixed {
  overflow: auto !important;
}

.text-nav-link {
  color: #89929b;
}
.text-nav-link:hover {
  color: #fff;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-gray-dt {
  color: #788896;
}

.ati__desc > * {
  margin-bottom: 0;
}

.btn-outline-purple.active {
  color: #fff;
  background-color: #6558f5;
  border-color: #6558f5;
}

.item-task:hover {
  background-color: #f3f6f9;
  border-radius: 5px;
}

.task-table-customize .odd .dataTables_empty {
  padding: 0 0 1rem 0;
}

.sbr-create-popup {
  max-height: 720px;
  min-height: 500px;
  overflow: scroll;
  scrollbar-width: none;
}
.sbr-create-popup .sbr-popup-limit {
  height: auto;
}

.sbr-configure {
  overflow: scroll;
  height: 100vh;
  padding: 25px 25px 100px 25px;
}

/* showMore.css */
.show-more-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.show-more-content.expanded {
  max-height: none; /* Để chiều cao tự động */
}

.navbar-menu .navbar-nav .nav-sm .nav-link-icon:before {
  width: 0 !important;
}

.navbar-menu .navbar-nav .nav-sm .nav-link-icon {
  padding-left: 1rem !important;
}

@media (max-width: 767px) {
  .pc-display {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .mb-display {
    display: block;
  }
}
@media (min-width: 767px) {
  .mb-display {
    display: none;
  }
}

@media (max-width: 767px) {
  .mb-visibility {
    visibility: visible;
  }
}
@media (min-width: 767px) {
  .mb-visibility {
    visibility: hidden;
  }
}

.mb-paginate {
  width: 100%;
  overflow-y: auto;
  max-height: 50px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .mb__set-width-800 {
    width: 800px;
  }
  .mb__set-width-1000 {
    width: 1000px;
  }
  .mb__set-width-1200 {
    width: 1200px;
  }
  .mb__card-body {
    overflow-y: auto;
  }
  .mb__card-body .mb__table-card {
    width: 1200px;
  }
  .mb__card-header {
    display: block !important;
  }
  .mb__card-header .card-title {
    padding-bottom: 0.75rem;
  }
  .redirect-block-mb .redirect-buttons-mb {
    gap: 10px;
  }
  .redirect-block-mb .redirect-buttons-mb .left-btn-block-mb {
    display: inline-flex;
    overflow-y: auto;
  }
  .redirect-block-mb .redirect-buttons-mb .left-btn-block-mb .btn {
    white-space: nowrap;
    height: 40px;
  }
  .redirect-block-mb .redirect-buttons-mb .btn-success {
    height: 40px;
  }
}
@media (max-width: 767px) {
  .communicate-sidebar {
    width: 400px;
  }
  .communicate-sidebar .offcanvas-header {
    width: 400px;
  }
  .communicate-sidebar .offcanvas-body {
    padding: 0;
  }
  .communicate-sidebar .offcanvas-body .task-block-top {
    width: 400px;
    overflow-y: auto;
    padding: 12px 6px;
  }
  .task-block-top__left ul.nav {
    flex-wrap: unset;
  }
  .task-block-top__left ul.nav li {
    white-space: nowrap;
  }
  .mb__operate-group-box {
    overflow-y: auto;
    height: 46px;
    padding-top: 8px;
    white-space: nowrap;
  }
  .mb__operate-group-box-report {
    overflow-y: auto;
    height: 43px;
    white-space: nowrap;
  }
  .mb__ovf-y-auto {
    overflow-y: auto;
  }
  .mb__mg-bot-10 {
    margin-bottom: 10px;
  }
  .mb__width-100 {
    width: 100%;
  }
  .mb__inline-flex {
    display: inline-flex;
    gap: 10px;
  }
  .mb__mg-reset nav .pagination {
    margin-bottom: 0;
  }
  .mb__header-buttons {
    display: inline-flex;
    overflow-y: auto;
    gap: 10px;
  }
  .mb__d-flex {
    display: flex;
  }
  .mb__d-block {
    display: block;
  }
  .mb__d-inline-flex {
    display: inline-flex;
  }
  .right-btn-block-task {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
  }
  .mb__mg-reset {
    margin-bottom: 0;
  }
  .mb__d-none {
    display: none;
  }
  .mb__justify-content-reset {
    justify-content: unset;
  }
  .mb__justify-content-end {
    justify-content: left;
  }
}
@media (min-width: 767px) {
  .pc__justify-content-end {
    justify-content: flex-end;
  }
  .notification-sidebar {
    width: 500px;
  }
  .communicate-sidebar {
    width: 885px;
  }
  .communicate-sidebar .offcanvas-body {
    padding: 12px 0;
  }
  .task-block-top {
    padding: 0 6px;
  }
  .pc__d-flex {
    display: flex;
  }
}
#react_history_info {
  visibility: collapse;
}

#react_history_paginate .pagination {
  justify-content: flex-start;
}

.visibility-hidden {
  visibility: hidden;
}

.content-20 {
  height: 20px;
  overflow: hidden;
}

.select2-result .select2-result-repository__avatar {
  float: left;
  margin-right: 10px;
  font-size: 20px;
}

.select2-result .select2-result-repository__title {
  font-weight: bold;
  font-size: 13px;
}

.select2-result .select2-result-repository__description {
  font-size: 12px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  margin-right: 28px !important;
  padding-right: 0 !important;
  margin-top: 5px !important;
  font-size: 16px;
  z-index: 2;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 10px;
}

.select2-result-repository__wrap__left {
  float: left;
}

.select2-result-repository__wrap__right {
  float: right;
  width: 100px;
}

.select2-result-repository__note {
  width: 100%;
  text-align: right;
  font-size: 12px;
}

.select2-result-repository__price {
  font-size: 12px;
  color: #F17171;
  margin: 0 30px;
}

/*****************/
.select2-container {
  display: block;
}

select + .select2-container {
  z-index: 1;
}

.select2-container *:focus {
  outline: 0;
}

.select2-container .select2-selection {
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .select2-container .select2-selection {
    transition: none;
  }
}
.select2-container.select2-container--focus .select2-selection, .select2-container.select2-container--open .select2-selection {
  border-color: #86b7fe;
}

.select2-container .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: #86b7fe;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container .select2-selection--multiple .select2-search .select2-search__field {
  width: 100%;
  font-family: inherit;
  background-color: transparent;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: rgba(91, 113, 185, 0.1);
  border: 1px solid rgba(91, 113, 185, 0.1);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-right: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #212529;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 6px;
}

.select2-container .select2-selection--multiple .select2-search .select2-search__field {
  font-size: 14px;
  height: 20px;
  margin-top: 8px;
}

.select2-dropdown {
  border: 1px solid #86b7fe;
}

/*****************/
.select2_color .select2-container .select2-selection--single .select2-selection__rendered {
  color: #fff !important;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__clear {
  color: #fff !important;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}

.select2_color .select2-container--default .select2-selection--single .select2-selection__arrow {
  background: hsla(0deg, 0%, 100%, 0.1);
  right: 0;
  top: 0;
  bottom: 0;
  height: 38px;
  border-radius: 0 3px 3px 0;
}

table.dataTable tr.dtrg-group th {
  background-color: #e9ecef !important;
}

table.table-increase tbody {
  counter-reset: rowNumber;
}

table.table-increase tbody tr > td.row_number {
  counter-increment: rowNumber;
  vertical-align: middle;
  line-height: normal;
}

table.table-increase tbody tr td.row_number::before {
  content: counter(rowNumber);
  min-width: 1em;
  margin-right: 0.5em;
}

.table tr .hidden-menu {
  opacity: 0;
}

.table tr:hover .hidden-menu {
  opacity: 1;
}

.table-diff-color tr:nth-child(even) {
  background-color: #f2f2f2; /* Màu cho các dòng chẵn */
}

/* Kích thước nhỏ (sm): Hiển thị từ breakpoint `sm` trở lên */
.offcanvas-sm {
  width: 300px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 576px) {
  .offcanvas-sm {
    width: 100%; /* Với màn hình nhỏ hơn `sm`, chiếm toàn bộ màn hình */
  }
}
/* Kích thước trung bình (md): Hiển thị từ breakpoint `md` trở lên */
.offcanvas-md {
  width: 500px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 768px) {
  .offcanvas-md {
    width: 100%; /* Với màn hình nhỏ hơn `md`, chiếm toàn bộ màn hình */
  }
}
/* Kích thước lớn (lg): Hiển thị từ breakpoint `lg` trở lên */
.offcanvas-lg {
  width: 800px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 992px) {
  .offcanvas-lg {
    width: 100%; /* Với màn hình nhỏ hơn `lg`, chiếm toàn bộ màn hình */
  }
}
/* Kích thước rất lớn (xl): Hiển thị từ breakpoint `xl` trở lên */
.offcanvas-xl {
  width: 66.67%; /* Ví dụ: Chiếm 2/3 màn hình */
}

@media (max-width: 1200px) {
  .offcanvas-xl {
    width: 100%; /* Với màn hình nhỏ hơn `xl`, chiếm toàn bộ màn hình */
  }
}
/* Kích thước đầy màn hình (full): Hiển thị trên mọi kích thước */
.offcanvas-full {
  width: 100%; /* Chiếm toàn bộ màn hình */
}