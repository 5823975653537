.remove-date,
.reset-input {
    position: relative;
}

.remove-date:hover .reset,
.reset-input:hover .reset {
    display: block !important;
}

.remove-date .range-del,
.remove-date .reset,
.reset-input .range-del,
.reset-input .reset {
    position: absolute;
    bottom: 10px;
    right: 5px;
    top: auto;
    cursor: pointer;
    z-index: 10;
    color: #999;
    display: none;
}
.media{
    display: flex!important;
}

.setting-item-icon{
    width: 40px;
    height: 40px;
    background-color: #f4f6f8;
    text-align: center;
    vertical-align: bottom;
    margin-right: 10px;
    line-height: 40px;
    font-size: 20px;
    border-radius: 3px;
    color: #8b97a4;
}

.multiselect{
    background-color: #fff;
    border-color: #d8dbe0;
    color: #768192;
}

.form-group {
    margin-bottom: 1rem;
}

.input-step-sm{
    height: 28.38px;
    input{
        width: 3em;
        font-size: .765625rem;
    }
    button{
        font-size: 1.2em;
        width: 1.2em;
    }
}

.card {
    .line-info {
        display: flex;
        margin-bottom: 7px;

        .title{
            width: 40%;
            float: left;
            display: flex;
            color: #747C87;
        }

        .content{
            width: 60%;
            float: left;
            font-size: 13px;
            display: flex;
            flex-wrap: wrap;
            color: #333;
        }

    }
}

.group-inline-tab-button{
    position: absolute;
    right: 15px;
    top: 12px;
}

/* NAV TABS LINK */
ul.tab-filter{
    border-bottom: 1px solid #f4f4f4;
}
ul.tab-filter li:first-child {
    width: 150px;
    padding: 0;
    margin: 0;
    border-right: 1px solid #f4f4f4;
}
ul.tab-filter li:first-child a{
    padding-left: 0;
    &:hover{
        color: #3c4b64;
    }
}
ul.tab-filter li a {
    padding: 5px 10px;
    color: #3c4b64;
    border-radius: 0;
    background: transparent;
    margin: 0;
    display: block;
    font-size: 13px;
    &:hover{
        text-decoration: none;
        color: #321fdb;
    }
}
ul.tab-filter li > a.active {
    color: #321fdb;
    font-weight: bold;
}
ul.tab-filter-first li a {
    padding-top: 0;
}
.box-stamp{
    position: absolute;
    left: 32%;
    top: 10%;
}
.stamp-pay-success{
    width: 200px;
    position: relative;
    top: 0;
    right: 0;
}

//attachments
.attachments {
    .attachment-box{
        border: 1px solid #eee;
    }
}

.attachment-name {
    color: #666;
    font-weight: 700;
    height: 40px;
    display: block;
}

.attachment-icon,
.attachment-info,
.attachment-size {
    display: block;
}

.attachment-info {
    background-color: #f8f9fa;
    padding: 10px;
}

.attachment-size {
    color: #999;
    font-size: 12px;

    > span {
        display: inline-block;
    }
}

.attachment-icon {
    color: #666;
    font-size: 65px;
    height: 150px;
    padding: 20px 10px;
    text-align: center;
    overflow: hidden;
    &.has-img {
        padding: 0;

        > img {
            width: 100%;
            height: 100%;
        }
    }
}

.collapse-filter-btn {
    position: absolute;
    top:1rem;
    right:1rem;
    @media (max-width: 767px) {
        top: unset;
        bottom: 1rem;
    }
}

.collapse-icon[aria-expanded=true] .la-angle-down {
    transform: rotate(180deg);
    transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-down {
    transform: rotate(180deg);
    transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-double-down {
    transform: rotate(180deg);
    transition: all 0.6s;
}

.collapse-icon[aria-expanded=true] .la-angle-double-down {
    transform: rotate(180deg);
    transition: all 0.6s;
}

.diff-color {
    background-color: #f2f2f2;
}

.btn-outline-purple {
    color: #6558f5;
    border-color: #6558f5;
    &:hover {
        background-color: #6558f5;
        color: #fff;
    }
}

.alert-count {
    width: 25px;
    height: 25px;
    line-height: 18px;
}

.caret-right {
    transform: rotate(0deg);
    transition: all 0.6s;

    &.active {
        transform: rotate(90deg);
    }
}

.tw-nav {
    padding: 0;

    .nav-tabs {
        border: unset;

        .nav-item {
            .nav-link.active {
                position: relative;
                background-color: #fff;

                &:after {
                    position: absolute;
                    content: '';
                    height: 2px;
                    bottom: 1px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background: #3d78e3;
                    -o-transition: .5s;
                    -ms-transition: .5s;
                    -moz-transition: .5s;
                    -webkit-transition: .5s;
                    transition: .5s;
                }
            }
        }
    }
}

.btn-purple {
    background-color: #6558f5;
    color: #fff;
    &:hover {
        background-color: #fff;
        color: #6558f5;
        border: 1px solid #6558f5;
    }
}

.full-width {
    width: 100%;
}

.order-navigate-popup {
    @media (max-width: 767px) {
        .left-block {
            button {
                width: 99%;
            }
        }
        .right-block {
            margin-top: 3px;
            .bill-popup-add-btn {
                width: 58%;
            }
            .bill-popup-close-btn {
                width: 40%;
            }
        }
    }
}

.tw-tab-content {
    .tab-pane {
    }
}

.text-purple {
    color: #6558f5;
}

.order-header {
    width: 100%;
    display: block;
    margin-bottom: .5rem;
    @media (max-width: 767px) {
        margin-top: 2rem;
        margin-bottom: .5rem;
    }
    .order-code, .order-request-form {
        width: 100%
    }
}

.content-bill {
    padding-top: 14px;
    border-top: 1px dashed #ccc;
}

.nav-link-order[data-bs-toggle=collapse]:after {
    right: -6px !important;
}

.over-flow-fixed {
    overflow: auto !important;
}

.text-nav-link{
    color: #89929b;
    &:hover{
        color: #fff;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-gray-dt {
    color: #788896;
}

.ati__desc > * {
    margin-bottom: 0;
}

.btn-outline-purple.active {
    color: #fff;
    background-color: #6558f5;
    border-color: #6558f5;
}

.item-task {
    &:hover {
        background-color: #f3f6f9;
        border-radius: 5px;
    }
}

.task-table-customize .odd .dataTables_empty {
    padding: 0 0 1rem 0;
}

.sbr-create-popup {
    max-height: 720px;
    min-height: 500px;
    overflow: scroll;
    scrollbar-width: none;
    .sbr-popup-limit {
        height: auto;
    }
}

.sbr-configure {
    overflow:scroll;
    height:100vh;
    padding: 25px 25px 100px 25px;
}

/* showMore.css */
.show-more-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.show-more-content.expanded {
    max-height: none; /* Để chiều cao tự động */
}

.navbar-menu .navbar-nav .nav-sm .nav-link-icon:before{
    width: 0 !important;
}
.navbar-menu .navbar-nav .nav-sm .nav-link-icon{
    padding-left: 1rem !important;
}

.pc-display {
    @media (max-width: 767px) {
        display: none !important;
    }
}

.mb-display {
    @media (max-width: 767px) {
        display: block;
    }
    @media (min-width: 767px) {
        display: none;
    }
}

.mb-visibility {
    @media (max-width: 767px) {
        visibility: visible;
    }
    @media (min-width: 767px) {
        visibility: hidden;
    }
}

.mb-paginate {
    width: 100%;
    overflow-y: auto;
    max-height: 50px;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .mb__set-width-800 {
        width: 800px;
    }

    .mb__set-width-1000 {
        width: 1000px;
    }

    .mb__set-width-1200 {
        width: 1200px;
    }

    .mb__card-body {
        overflow-y: auto;

        .mb__table-card {
            width: 1200px;
        }
    }

    .mb__card-header {
        display: block !important;

        .card-title {
            padding-bottom: .75rem;
        }
    }

    .redirect-block-mb {
        .redirect-buttons-mb {
            gap: 10px;

            .left-btn-block-mb {
                display: inline-flex;
                overflow-y: auto;

                .btn {
                    white-space: nowrap;
                    height: 40px;
                }
            }

            .btn-success {
                height: 40px;
            }
        }
    }
}

@media (max-width: 767px) {
    .communicate-sidebar {
        width: 400px;
        .offcanvas-header {
            width: 400px;
        }
        .offcanvas-body {
            padding: 0;
            .task-block-top {
                width: 400px;
                overflow-y: auto;
                padding: 12px 6px;
            }
        }
    }

    .task-block-top__left {
        ul.nav {
            flex-wrap: unset;
            li {
                white-space: nowrap;
            }
        }
    }

    .mb__operate-group-box {
        overflow-y: auto;
        height: 46px;
        padding-top: 8px;
        white-space: nowrap;
    }

    .mb__operate-group-box-report {
        overflow-y: auto;
        height: 43px;
        white-space: nowrap;
    }

    .mb__ovf-y-auto {
        overflow-y: auto;
    }
    .mb__mg-bot-10 {
        margin-bottom: 10px;
    }
    .mb__width-100 {
        width: 100%;
    }

    .mb__inline-flex {
        display: inline-flex;
        gap: 10px;
    }

    .mb__mg-reset {
        nav {
            .pagination {
                margin-bottom: 0;
            }
        }
    }

    .mb__header-buttons {
        display: inline-flex;
        overflow-y: auto;
        gap: 10px;
    }

    .mb__d-flex {
      display: flex;
    }

    .mb__d-block {
        display: block;
    }

    .mb__d-inline-flex {
        display: inline-flex;
    }

    .right-btn-block-task {
        display: flex;
        justify-content: space-between;
        margin-top: .75rem;
    }

    .mb__mg-reset {
        margin-bottom: 0;
    }

    .mb__d-none {
        display: none;
    }

    .mb__justify-content-reset {
        justify-content: unset;
    }

    .mb__justify-content-end {
        justify-content: left;
    }
}

@media (min-width: 767px) {
    .pc__justify-content-end {
        justify-content: flex-end;
    }

    .notification-sidebar {
        width: 500px;
    }

    .communicate-sidebar {
        width: 885px;
        .offcanvas-body {
            padding: 12px 0;
        }
    }

    .task-block-top {
        padding: 0 6px;
    }

    .pc__d-flex {
        display: flex;
    }
}

#react_history_info {
    visibility: collapse;
}

#react_history_paginate {
    .pagination {
        justify-content: flex-start;
    }
}

.visibility-hidden {
    visibility: hidden;
}

.content-20{
    height: 20px;
    overflow: hidden;
}
