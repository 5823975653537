/* Kích thước nhỏ (sm): Hiển thị từ breakpoint `sm` trở lên */
.offcanvas-sm {
    width: 300px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 576px) {
    .offcanvas-sm {
        width: 100%; /* Với màn hình nhỏ hơn `sm`, chiếm toàn bộ màn hình */
    }
}

/* Kích thước trung bình (md): Hiển thị từ breakpoint `md` trở lên */
.offcanvas-md {
    width: 500px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 768px) {
    .offcanvas-md {
        width: 100%; /* Với màn hình nhỏ hơn `md`, chiếm toàn bộ màn hình */
    }
}

/* Kích thước lớn (lg): Hiển thị từ breakpoint `lg` trở lên */
.offcanvas-lg {
    width: 800px; /* Tùy chỉnh theo ý bạn */
}

@media (max-width: 992px) {
    .offcanvas-lg {
        width: 100%; /* Với màn hình nhỏ hơn `lg`, chiếm toàn bộ màn hình */
    }
}

/* Kích thước rất lớn (xl): Hiển thị từ breakpoint `xl` trở lên */
.offcanvas-xl {
    width: 66.67%; /* Ví dụ: Chiếm 2/3 màn hình */
}

@media (max-width: 1200px) {
    .offcanvas-xl {
        width: 100%; /* Với màn hình nhỏ hơn `xl`, chiếm toàn bộ màn hình */
    }
}

/* Kích thước đầy màn hình (full): Hiển thị trên mọi kích thước */
.offcanvas-full {
    width: 100%; /* Chiếm toàn bộ màn hình */
}
